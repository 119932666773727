/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap');
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@import "~@angular/material/prebuilt-themes/indigo-pink.css"; // your theme

@import "assets/css/style.css";

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body{
    font-family: 'Poppins', sans-serif;
}
.mat-stepper-horizontal, .mat-stepper-vertical {
    background-color: transparent;
}
.min-h-100vh{
    min-height: 100vh;
}
.mat-vertical-content{
    padding: 0 0px 0px 0px !important;
}
.mat-vertical-content-container{
    margin-left: 15px !important;
}
.form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}
.pl-40{
    padding-left: 55px !important;
    padding-right: 45px  !important;
}
.rightView-Wrapper{
    // background-color: #f5f5f5;
    padding: 0px 0px;
    position: relative;
    height: calc(100vh - 140px);
}
.stepper-wrapper{
  .div-header{
    height: 140px;
    background-color: #eaeaec;
    display: flex;
    align-items: center;
    h2 {
        font-size: 60px;
        font-weight: 900;
        line-height: 64px;
        margin-bottom: 0px;
        letter-spacing: 1px;
        text-transform: capitalize;
        color: #4d4d4d;
        z-index: 999;
        position: relative;
        text-align: center;
        width: 100%;
        display: block;
    }
    @media (max-width: 768px){
        height: 120px;
    }
  }

    max-width: 1920px;
    margin: 0 auto;
    .mat-mdc-text-field-wrapper{
        color: #275eb8 !important;
        border-bottom: 2px solid #275eb8 !important;
        border: 0;
        border-radius: 0;
        padding-left: 0;
        font-size: 26px;
        background-color: transparent;
    }
    .tp-form {
        max-width: 100%;
        width: 100%;
    }
    h1{
        color: #000;
        font-size: 22px;
        display: flex;
        margin-bottom: 15px;
        font-weight: 400;
        position: relative;
        padding: 0;
        text-transform: capitalize;
        span{
            color: #0142ac !important;
            font-size: 14px;
            margin-right: 0;
            left: -46px;
            font-weight: 400;
            position: absolute;
            width: 36px;
            @media (max-width: 768px){
                left: -38px;
                width: auto;
                font-size: 13px;
            }
            svg{
                width: 16px;
                @media (max-width: 768px){
                    width: 14px;
                }
                path{
                    fill:#0142ac ;
                }
            }
        }
    }
    p{
        color: #757575;
        font-size: 12px;
    }
    .form-control{
        color: #275eb8!important;
        // color: #aaadb1 !important;
        border-bottom: 2px solid #dfdfdfa1 !important;
        border: 0;
        border-radius: 0;
        padding-left: 0;
        font-size: 26px;
    }
    .btn-grp{
        display: flex;
        align-items: center;
        margin-top: 15px;
        @media only screen and (max-width: 768px) {
            background: #fff;
        }
        .ml-2{
            margin-left: 15px;
        }
        .mat-custom-button{
            font-size: 18px!important;
            min-height: 40px;
            width: 86px;
            background: rgb(1, 66, 172);
            color: rgb(255, 255, 255) !important;
            z-index: 1;
            border-radius: 4px;
            border-width: 0px;
            @media only screen and (max-width: 768px) {
                font-size: 16px !important;
                min-height: 36px;
                width: 75px;
            }
            .tick{
                border-bottom: 3px solid #fff;
                border-right: 3px solid #fff;
                display: inline-block;
                height: 14px;
                margin-left: 6px;
                transform: rotate(45deg);
                width: 7px;
            }
          }
    }
    ::placeholder {
        color: #dfdfdf !important;
        opacity: 1; /* Firefox */
        font-weight: 300;
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
       color: #dfdfdf !important;
       font-weight: 300;
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
       color: #dfdfdf !important;
       font-weight: 300;
      }

}

.first-right-section{
    position: relative;
    height: 100vh;
    .iFrame-{
        position:absolute ;
        top: 0px;
        width: 100%;

        iframe{
            height: 100vh;
            width: 100%;

        }

    }
    .bg-overlay{
        &::after{
            background-color: #0000009c;
            height: 100vh;
            width: 100%;
            display: block;
            z-index: 1;
            position: absolute;
            content: "";
        }
    }
    h1{
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 20px;
        letter-spacing: 10px;
        text-transform: uppercase;
        color: #fff;
        z-index: 999;
        position: relative;
        text-align: center;
        width: 100%;
        display: block;
    }
    h6{
        font-size: 60px;
        line-height: 60px;
        font-weight: 800;
        z-index: 999;
        position: relative;
        text-align: center;
        width: 100%;
        display: block;
        color: #fff;
    }
    .center-align{
        position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    width: 100%;
    }
}

.center-wrapper{
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 52%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
}
[contentEditable=true]:empty:not(:focus):before {
    content: attr(data-text)
  }
 .pointer-envents-none{
    pointer-events:none;

    div:focus {
      outline:0;
      content: attr(data-text);
    }
 }

 .about-text{
    color: #000000;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 20px;
    padding: 0px 70px;
 }

 .introduce-text{
    text-align: center;
    color: #999999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 10px;
    padding: 0px 70px;
 }

 .aboutContent-text{
    text-align: center;
    color: #000000;
    font-size: 15px;
    font-weight: 400;
    padding: 0px 70px;
 }
 .divider-line{
    position: relative;
    margin-bottom: 50px;

    /** &::before{
        position: absolute;
        content: '';
        width: 30px;
        height: 2px;
        color: #777;
        background-color: #777;
        top: 0;
        left: 50%;
      transform: translate(-50%, -50%);
    }
    **/
 }
 div.mat-mdc-autocomplete-panel {
    @media only screen and (max-width: 768px) {
        padding: 2px 0 0 !important;
    }
}
 .mat-mdc-autocomplete-panel{
    padding: 0px 0px;
    background: #fff;
    box-shadow: none;



    .mdc-list-item{
      align-items: center!important;
      background: rgba(1,66,172,.1)!important;
      border-color: #0142ac!important;
      border-radius: 2px;
      color: #0142ac!important;
      cursor: pointer!important;
      display: flex;
      flex-direction: row-reverse!important;
      height: inherit;
      max-width: 100%!important;
      min-height: 40px!important;
      opacity: 1!important;
      outline: 0!important;
      padding-bottom: 4px!important;
      padding-top: 4px!important;
      position: relative!important;
      transition-duration: .1s!important;
      transition-property: background-color,color,border-color,opacity,box-shadow!important;
      transition-timing-function: ease-out!important;
      width: 100%;
      word-break: break-word!important;
      border: 1px solid;
      margin-bottom: 5px;
      @media only screen and (max-width: 768px) {
        min-height: 28px !important;
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        margin-bottom: 3px;
        font-size: 12px;
        padding: 0 5px;
      }
      .mdc-list-item__primary-text{
        color: #0142ac!important;
      }
      .mat-pseudo-checkbox{
        position: absolute;
        right: 20px;
      }
    }
  }

  .mat-mdc-form-field{
    &:hover{
        .mat-mdc-form-field-focus-overlay {
            opacity: 0;
        }
    }
  }
  .mat-mdc-form-field-input-control{
    color: #275eb8 !important;
    border: 0;
    border-radius: 0;
    padding-left: 0;
    font-size: 26px;
}
.third-step{
    .aboutContent-text {
        text-align: center;
        color: #000000;
        font-size: 12px;
        font-weight: 400;
        padding: 0px 0px;
    }
    h3{
        font-weight: 600;
        margin-top: 5px;
        margin-bottom: 5px;
        @media only screen and (max-width: 768px) {
            margin-bottom: 0;
            font-size: 14px;
        }
    }
    .lnr{
        font-size: 36px;
        color: #0042ac;
    }
    .col-md-4{
        padding-right: 0px;
        margin-bottom: 15px;
        @media only screen and (max-width: 768px) {
            padding-left: 0;
        }
    }
}

.Done-wrapper{
    min-height: 100vh;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
    .done-wrapper-inner{
        text-align: center;
        h2{
            color: #000;
        font-size: 22px;
        margin-bottom: 15px;
        font-weight: 500;
        }
        .back-btn{
            font-size: 18px !important;
        min-height: 40px;
        width: 110px;
        background: rgb(1, 66, 172);
        color: rgb(255, 255, 255) !important;
        z-index: 1;
        border-radius: 4px;
        border-width: 0px;
        margin-right: 10px;
        }
        .reset-btn{
            font-size: 18px !important;
        min-height: 40px;
        width: 110px;
        background: #24b7a4;
        color: rgb(255, 255, 255) !important;
        z-index: 1;
        border-radius: 4px;
        border-width: 0px;
        }
    }

}
.btn-sample{
    background-color: #b7b8b8;
    border: 0;
    max-width: 100%;
    width: 320px;
    padding: 22px 10px;
    font-size: 24px;
    display: inline-flex;
    justify-content: center;
    color: #fff;
    border: 2px solid #b7b8b8;
    text-transform: uppercase;
    mat-icon{
        margin-right: 10px;
    }
}
.btn-grp-column{
    position: fixed;
    opacity: inherit;
    right: 0px;
    left: 60px;
    bottom: -10px;
    z-index: 10;
    padding: 0;
    &.service-btn{
        left: 50px;
        @media only screen and (max-width: 768px) {
            left: 15px;
        }
    }
    @media only screen and (max-width: 768px) {
        left: 15px;
    }
}
.plusIcon{
    .plus-icon {
        font-size: 16px;
        padding-right: 5px;
    }
    .text-icon {
        border-bottom: 1px solid #0142ac;
    }
}
.checkbox-field {
    padding-top: 30px;
  }
  .checkbox-field .checkbox-container {
    display: block;
    position: relative;
    padding-left: 32px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checkbox-field .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: -2px;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #fff;
    border: 2px solid #0142ac;
  }
  .checkbox-field .checkbox-container:hover input ~ .checkmark {
    background-color: #fff;
  }
  .checkbox-field .checkbox-container input:checked ~ .checkmark {
    background-color: #0142ac;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .checkbox-field .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  .checkbox-field .checkbox-container .checkmark:after {
    left: 6px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
@media only screen and (max-width: 768px) {
    .rightView-Wrapper .row.center-wrapper {
        margin: 90px 0 50px;
    }
    .btn-sample {
        width: 290px;
        padding: 18px 10px;
        font-size: 20px;
    }
    .center-wrapper {
        position: relative;
        top: 0;
        transform: initial;
        left: 0;
        margin-top: 20px;
    }
    .mat-vertical-content-container {
        margin-left: 0px !important;
    }
    .col-md-6{
        padding: 0px !important;
    }
    .pl-40 {
        order: 2;
        padding-top: 20px !important;
        padding-bottom: 80px  !important;
        padding-left: 55px !important;
        padding-right: 20px !important;
        &.padding-left-decrease{
            padding-left: 20px !important;
        }
    }

    .first-right-section{
        height: auto;
        .iFrame-{
            position:absolute ;
            top: 0px;
            width: 100%;

            iframe{
                height: 50vh;
                width: 100%;
            }
        }
        .bg-overlay{
            &::after{
                display: none;
            }
        }
    }
    .min-h-100vh {
        min-height: auto;
    }
    .stepper-wrapper{
        .form-control{
            font-size: 16px;
            &::placeholder{
                font-size: 13px;
            }
        }
        .mat-mdc-text-field-wrapper {
            font-size: 18px;
        }
        h1 {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 10px;
            padding-left: 0;
            position: relative;
            line-height: 1.2;
            margin: 0;
            @media (max-width: 399px){
                font-size: 13px;
            }
        }
         .div-header
         {
            h2 {
                font-size: 40px !important;
                font-weight: 900 !important;
                line-height: 35px !important;
                @media (max-width: 340px){
                    font-size: 37px !important;
                }
            }
         }

    }
    .row{
        padding: 0px  !important;
    }

    .add-service-button2{
        margin-bottom: 5px !important;
    }
    .plusIcon{
        top: 10px !important;
    }
    .mb-5 {
        margin-bottom: 1rem !important;
    }
    .rightView-Wrapper {
        height: auto !important;
    }
    .aboutContent-text{
        padding: 0 !important;
        line-height: 1.2;
    }
    .divider-line {
        margin-bottom: 20px !important;
    }
    .pl-1 , .pr-1{
        padding-left: 20px !important;
    }
    .padding-row{
        padding: 0px 15px !important;
    }
    .min-h-100vhh{
        min-height: 90vh;
    }
    .center-mobile{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
    }
    .checkbox-field .checkbox-container{
        font-size: 13px;
    }
    .checkmark {
        height: 20px;
        width: 20px;
        border: 1px solid #0142ac;
    }
    .checkbox-field .checkbox-container .checkmark:after {
        top: 1px;
        height: 11px;
    }
    .testimonail-inner {
        margin: 0;
        padding: 70px 0 50px !important;
    }
    .first-right-section{
        .center-align{
            position: static;
            transform: inherit;
            background: #0000009c;
            padding: 150px 0 10px;
            margin-bottom: 30px;
            h1{
                margin-bottom: 10px;
            }
            h6{
                margin: 0;
            }
        }
    }
}
@media only screen and (max-width: 386px) {
    .first-right-section h6{
        font-size: 50px;
        line-height: 50px;
        font-weight: 600;
    }
    .first-right-section h1{
        font-size: 15px !important;
        margin-bottom: 10px;
    }
}
.image-{
  max-width: 220px;
  max-height: 220px;
}
.file-upload .image- {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: initial;
}